import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import io from "socket.io-client";

const NotificationContext = createContext();

const RECONNECTION_ATTEMPTS = 5;
const RECONNECTION_DELAY = 1000;
const CONNECTION_STATES = {
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  CONNECTING: "connecting",
  ERROR: "error",
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState(
    CONNECTION_STATES.DISCONNECTED
  );
  const [retryCount, setRetryCount] = useState(0);
  const socketRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const notificationSoundRef = useRef(null);

  const userToken = localStorage.getItem("userToken");

  // Initialize audio
  useEffect(() => {
    notificationSoundRef.current = new Audio(
      process.env.PUBLIC_URL + "/sound/notification-sound.mp3"
    );
    notificationSoundRef.current.load();

    const handleUserInteraction = () => {
      if (notificationSoundRef.current) {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.suspend();

        notificationSoundRef.current
          .play()
          .then(() => notificationSoundRef.current.pause())
          .catch((e) => console.log("Audio context initialization:", e));
      }
      document.removeEventListener("click", handleUserInteraction);
    };

    document.addEventListener("click", handleUserInteraction);

    return () => {
      document.removeEventListener("click", handleUserInteraction);
      if (notificationSoundRef.current) {
        notificationSoundRef.current.pause();
        notificationSoundRef.current = null;
      }
    };
  }, []);

  const fetchNotifications = useCallback(async () => {
    if (!userToken) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/notifications`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setNotifications(response.data.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, [userToken]);

  const initializeSocket = useCallback(() => {
    if (!userToken || socketRef.current?.connected) return;

    setConnectionStatus(CONNECTION_STATES.CONNECTING);

    // Clear existing socket if any
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    const newSocket = io(process.env.REACT_APP_API_URL, {
      withCredentials: true,
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: RECONNECTION_ATTEMPTS,
      reconnectionDelay: RECONNECTION_DELAY,
      auth: {
        token: userToken,
      },
    });

    const handleConnect = () => {
      //  console.log("Socket connected successfully");
      setConnectionStatus(CONNECTION_STATES.CONNECTED);
      setRetryCount(0);
      newSocket.emit("joinNotificationRoom", userToken);
      fetchNotifications();
    };

    const handleConnectError = (error) => {
      console.error("Socket connection error:", error);
      setConnectionStatus(CONNECTION_STATES.ERROR);

      if (retryCount < RECONNECTION_ATTEMPTS) {
        setRetryCount((prev) => prev + 1);
        reconnectTimeoutRef.current = setTimeout(() => {
          console.log(
            `Attempting reconnection (${
              retryCount + 1
            }/${RECONNECTION_ATTEMPTS})`
          );
          initializeSocket();
        }, RECONNECTION_DELAY * (retryCount + 1));
      }
    };

    const handleDisconnect = (reason) => {
      //  console.log("Socket disconnected:", reason);
      setConnectionStatus(CONNECTION_STATES.DISCONNECTED);

      if (reason === "io server disconnect") {
        initializeSocket();
      }
    };

    const handleNewNotification = (notification) => {
      if (!notification) return;

      if (notificationSoundRef.current) {
        notificationSoundRef.current.currentTime = 0; // Reset to start
        notificationSoundRef.current.play().catch((e) => {
          console.log("Error playing sound:", e);
          if (e.name === "NotAllowedError") {
            console.log("Audio playback requires user interaction first");
          } else if (e.name === "NotSupportedError") {
            console.log("Audio format not supported, trying to reload audio");
            notificationSoundRef.current.load();
          }
        });
      }

      setNotifications((prev) => {
        const exists = prev.some((n) => n._id === notification._id);
        if (exists) return prev;
        return [notification, ...prev];
      });

      if (Notification.permission === "granted") {
        new Notification(notification.title, {
          body: notification.message,
          icon: "/notification-icon.png",
          tag: notification._id,
        });
      }
    };

    // Set up event listeners
    newSocket.on("connect", handleConnect);
    newSocket.on("connect_error", handleConnectError);
    newSocket.on("disconnect", handleDisconnect);
    newSocket.on("newNotification", handleNewNotification);
    newSocket.on("roomJoined", (data) => {
      // console.log("Room joined:", data);
    });
    newSocket.on("roomError", (error) => console.error("Room error:", error));

    // Ping/Pong for connection monitoring
    const pingInterval = setInterval(() => {
      if (newSocket.connected) {
        newSocket.emit("ping");
      }
    }, 30000);

    socketRef.current = newSocket;

    return () => {
      clearInterval(pingInterval);
      clearTimeout(reconnectTimeoutRef.current);
      if (newSocket) {
        newSocket.off("connect");
        newSocket.off("connect_error");
        newSocket.off("disconnect");
        newSocket.off("newNotification");
        newSocket.off("roomJoined");
        newSocket.off("roomError");
        newSocket.disconnect();
      }
    };
  }, [userToken, retryCount, fetchNotifications]);

  useEffect(() => {
    initializeSocket();

    return () => {
      clearTimeout(reconnectTimeoutRef.current);
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [initializeSocket]);

  const markAsRead = useCallback(
    async (notificationId) => {
      if (!userToken || !notificationId) return;

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/notifications/${notificationId}/read`,
          null,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.data.success) {
          setNotifications((prev) =>
            prev.map((notif) =>
              notif._id === notificationId ? { ...notif, isRead: true } : notif
            )
          );

          if (socketRef.current?.connected) {
            socketRef.current.emit("notificationRead", {
              userId: userToken,
              notificationId: notificationId,
            });
          }
        }
      } catch (error) {
        console.error("Error marking notification as read:", error);
      }
    },
    [userToken]
  );

  const deleteNotification = useCallback(
    async (notificationId) => {
      if (!userToken || !notificationId) return;

      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/notifications/${notificationId}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.data.success) {
          setNotifications((prev) =>
            prev.filter((notif) => notif._id !== notificationId)
          );
        }
      } catch (error) {
        console.error("Error deleting notification:", error);
      }
    },
    [userToken]
  );

  const value = {
    notifications,
    markAsRead,
    deleteNotification,
    unreadCount: notifications.filter((n) => !n.isRead).length,
    fetchNotifications,
    connectionStatus,
    isConnected: connectionStatus === CONNECTION_STATES.CONNECTED,
    retryCount,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationProvider"
    );
  }
  return context;
};

export default NotificationContext;
