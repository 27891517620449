import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Facebook, Youtube, Instagram } from "lucide-react";

import appstore from "../../img/AppStore.png";
import playstore from "../../img/PlayStore.png";
import top from "../../img/icn/top.png";
import Logo from "../../img/icn/Logo.png";

const Footer = () => {
  const { t } = useTranslation();
  // Setting font style
  const font = localStorage.getItem("lng");

  let setFontSize = font === "bn" ? "text-lg" : "";

  const [showTopButton, setShowTopButton] = useState(false);

  // Show "Back to Top" button when scrolled down
  useEffect(() => {
    const handleScroll = () => {
      setShowTopButton(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="mt-5 relative">
      <footer className={` bg-slate-900 py-8 px-4 sm:px-6 lg:px-8`}>
        <div className=" md:max-w-[85%]   mx-auto mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
            <div className="col-span-1 md:col-span-2 lg:col-span-2">
              <Link
                to="https://www.lokoproperty.com"
                className="inline-block mb-4"
              >
                <div className="flex items-center space-x-2">
                  <img
                    className="h-8 sm:h-10"
                    src={Logo}
                    alt="Loko Property Logo"
                  />
                  <p className="text-xl sm:text-2xl  font-bold text-[#f5882b]">
                    LOKO PROPERTY
                  </p>
                </div>
              </Link>
              <p
                className={`text-sm sm:text-sm text-justify font-thin mb-2 text-gray-200 ${setFontSize}`}
              >
                {t(
                  "footer.loko_property_your_trusted_platform_for_property_buying_selling_renting_and_group_wise_building_construction"
                )}
              </p>
            </div>

            <div>
              <h3
                className={`text-lg font-semibold text-gray-200 mb-4 ${setFontSize}`}
              >
                {t("footer.Download_our_app")}
              </h3>
              <div className="">
                <img
                  className="h-10 cursor-pointer"
                  src={appstore}
                  alt="App Store"
                />
                <img
                  className="h-10 cursor-pointer"
                  src={playstore}
                  alt="Play Store"
                />
              </div>
            </div>

            <div>
              <h3
                className={`text-lg font-semibold text-gray-200 mb-4 ${setFontSize}`}
              >
                {t("footer.Help_and_support")}
              </h3>
              <ul className="space-y-2">
                {[
                  { label: t("footer.FAQ"), path: "/Faq" },
                  { label: t("footer.Stay_Safe"), path: "/Stay-Safe" },
                  { label: t("footer.Contact_Us"), path: "/ContactUs" },
                ].map(({ label, path }) => (
                  <li key={label}>
                    <Link
                      to={path}
                      className="text-gray-300 hover:text-orange-400 text-sm"
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3
                className={`text-lg font-semibold text-gray-200 mb-4 ${setFontSize}`}
              >
                {t("footer.Follow_Loko_Property_On")}
              </h3>
              <ul className="space-y-2">
                {[
                  {
                    label: "Facebook",
                    path: "https://www.facebook.com/LokoProperty",
                    icon: Facebook,
                  },
                  {
                    label: "Youtube",
                    path: "https://www.youtube.com/@Loko-Property",
                    icon: Youtube,
                  },
                  {
                    label: "Instagram",
                    path: "https://www.instagram.com/lokoproperty",
                    icon: Instagram,
                  },
                ].map(({ label, path, icon: Icon }) => (
                  <li key={label}>
                    <Link
                      to={path}
                      className="text-gray-300 hover:text-orange-400 text-sm flex items-center gap-2"
                    >
                      <Icon size={20} />
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="mt-8 pt-8 border-t border-gray-700">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <h3
                  className={`text-lg font-semibold text-gray-200 mb-4 ${setFontSize}`}
                >
                  {t("footer.About_Loko_Property")}
                </h3>
                <ul className="space-y-2">
                  {[
                    { label: t("footer.About_Us"), path: "/AboutUs" },
                    {
                      label: t("footer.Terms_and_conditions"),
                      path: "/TermsAndConditions",
                    },
                    {
                      label: t("footer.Privacy_policy"),
                      path: "/Privacy-Policy",
                    },
                  ].map(({ label, path }) => (
                    <li key={label}>
                      <Link
                        to={path}
                        className="text-gray-300 hover:text-orange-400 text-sm"
                      >
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3
                  className={`text-lg font-semibold text-gray-200 mb-4 ${setFontSize}`}
                >
                  {t("footer.More_about_service")}
                </h3>
                <Link
                  to="/FastSellRent"
                  className="text-gray-300 hover:text-orange-400 text-sm"
                >
                  {t("footer.Fast_sell_Rent")}
                </Link>
              </div>
            </div>
          </div>

          <div className="mt-8 pt-8 border-t border-gray-700">
            <div className=" text-center">
              <p className={`text-sm text-gray-200  ${setFontSize}`}>
                {t("footer.copyright")}
              </p>
            </div>
          </div>
        </div>
      </footer>

      <motion.button
        initial={{ opacity: 0, scale: 0.5 }}
        animate={
          showTopButton ? { opacity: 1, scale: 1 } : { opacity: 0, y: 50 }
        }
        transition={{ duration: 0.3 }}
        className="fixed right-4 bottom-4 bg-orange-500 text-white p-3 rounded-full shadow-lg hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
        onClick={scrollToTop}
        aria-label="Scroll to top"
      >
        <img src={top} alt="Scroll to top" className="w-6 h-6" />
      </motion.button>
    </div>
  );
};

export default Footer;
