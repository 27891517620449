import "./App.css";
import React, { useEffect, Suspense } from "react";

import axios from "axios";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationProvider } from "./context/NotificationContext";
// Core components
import Navbar from "./components/LocalUi/Navbar";
import Footer from "./components/LocalUi/Footer";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import UserIsNotSuspendRoute from "./components/privateRoute/UserIsNotSuspendRoute";
import AdRoute from "./components/privateRoute/AdRoute";
import { UserProvider } from "./context/UserContext";
import ScrollToTop from "./components/LocalUi/ScrollToTop";

//Admin Core components
import Dashboard from "./pages/admin/DashBoard";
import UserManagement from "./pages/admin/UserManagement";
import AdminInfo from "./pages/admin/AdminInfo";
import GroupBuildingManage from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/GroupBuildingManage";
import Notification from "./pages/admin/Notifications";
import SellPostManagement from "./pages/admin/PropertyManagement/PropertySellManagement/SellPostManagement";
import RentPostManagement from "./pages/admin/PropertyManagement/PropertyRentManagement/RentPostManagement";
import NoticeBoard from "./pages/admin/NoticeBoard";
import BlogPost from "./pages/admin/BlogPost";
import InteriorManagement from "./pages/admin/PropertyManagement/InteriorManagement/InteriorManagement";
import SiteSettings from "./pages/admin/SiteSettings";
import GroupBuildingDesiredRequest from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/GroupBuildingDesiredRequest";

// pages
const Home = React.lazy(() => import("./pages/Home"));
const BlogPosts = React.lazy(() => import("./pages/BlogPosts/BlogPosts"));
const BlogPostDetails = React.lazy(() =>
  import("./pages/BlogPosts/BlogPostDetails")
);
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const CustomerCare = React.lazy(() => import("./pages/CustomerCare"));
const DownloadApp = React.lazy(() => import("./pages/DownloadApp"));

// Property Pages
const PropertySell = React.lazy(() =>
  import("./pages/PropertyPages/PropertySell/PropertySell")
);
const PropertySellDetails = React.lazy(() =>
  import("./pages/PropertyPages/PropertySell/PropertySellDetails")
);
const GroupWiseBuilding = React.lazy(() =>
  import("./pages/PropertyPages/GroupWiseBuilding/GroupWiseBuilding")
);
const GroupWiseBuildingDetails = React.lazy(() =>
  import("./pages/PropertyPages/GroupWiseBuilding/GroupWiseBuildingDetails")
);
const PropertyRent = React.lazy(() =>
  import("./pages/PropertyPages/PropertyRent/PropertyRent")
);
const PropertyRentDetails = React.lazy(() =>
  import("./pages/PropertyPages/PropertyRent/PropertyRentDetails")
);
const SellPost = React.lazy(() =>
  import("./pages/PropertyPages/PropertySell/SellPost")
);
const RentPost = React.lazy(() =>
  import("./pages/PropertyPages/PropertyRent/RentPost")
);
const PropertySearchPage = React.lazy(() =>
  import("./pages/PropertyPages/PropertySearchPage/PropertySearchPage")
);

// User Pages
const SignIn = React.lazy(() => import("./pages/user/SignIn"));
const Register = React.lazy(() => import("./pages/user/Register"));
const ForgotPassword = React.lazy(() => import("./pages/user/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/user/ResetPassword"));
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const EditProfile = React.lazy(() => import("./pages/user/EditProfile"));
const EditRentPost = React.lazy(() => import("./pages/user/EditRentPost"));
const EditSellPost = React.lazy(() => import("./pages/user/EditSellPost"));
const UserDashboard = React.lazy(() => import("./pages/user/UserDashboard"));

// Admin Pages
const Admin = React.lazy(() => import("./pages/admin/Login"));

const SetGroupBuildingPost = React.lazy(() =>
  import(
    "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/SetGroupBuildingPost"
  )
);

const InteriorAddPost = React.lazy(() =>
  import("./pages/admin/PropertyManagement/InteriorManagement/InteriorAddPost")
);
const UpdateInteriorPost = React.lazy(() =>
  import("./pages/admin/PropertyManagement/InteriorManagement/InteriorPostEdit")
);

const GroupPostDetailsForAd = React.lazy(() =>
  import(
    "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/GroupPostDetailsForAd"
  )
);
const UpdateGroupBuildingPost = React.lazy(() =>
  import(
    "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/UpdateGroupBuildingPost"
  )
);

// Other Pages
const FastSellRent = React.lazy(() =>
  import("./pages/OthersPage/FastSellRent")
);
const PrivacyPolicy = React.lazy(() =>
  import("./pages/OthersPage/PrivacyPolicy")
);
const StaySafe = React.lazy(() => import("./pages/OthersPage/StaySafe"));
const TermsAndConditions = React.lazy(() =>
  import("./pages/OthersPage/TermsAndConditions")
);
const InteriorProperty = React.lazy(() =>
  import("./pages/PropertyPages/Interior/InteriorProperty")
);
const InteriorPropertyDetails = React.lazy(() =>
  import("./pages/PropertyPages/Interior/InteriorPropertyDetails")
);
const UsersDashboard = React.lazy(() =>
  import("./pages/UsersData/UsersDashboard")
);

// Loading component for Suspense fallback
const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className=""></div>
  </div>
);

function App() {
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  // Function to send visitor data to the backend
  const sendVisitorData = async () => {
    const isMobile = isMobileDevice();
    const data = {
      month: new Date().toLocaleString("default", { month: "long" }), // Get current month (e.g., "July")
      deviceType: isMobile ? "mobile" : "desktop",
    };

    try {
      // Use axios to send a POST request
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/dvd`,
        data
      );
    } catch (error) {
      console.error("Error sending visitor data:", error);
    }
  };

  useEffect(() => {
    // Call the visitor data function when the app is loaded
    sendVisitorData();
  }, []); // Empty dependency array ensures this runs once on mount

  const { t, i18n } = useTranslation();
  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (!font) {
    setFont = "font-TiroBangla";
  }
  if (font == "bn") {
    setFont = "font-TiroBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  return (
    <BrowserRouter
      future={{
        v7_startTransition: true,
      }}
    >
      <div className={` bg-[#ededed] flex flex-col ${setFont}  min-h-screen `}>
        <UserProvider>
          <NotificationProvider>
            <div className="flex-1  ">
              <Navbar />
              <Toaster
                position="top-center"
                toastOptions={{ duration: 5000 }}
              />
              <ScrollToTop />

              <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/Home" element={<Home />} />
                  <Route
                    path="/property-search"
                    element={<PropertySearchPage />}
                  />
                  <Route path="/Property-Buy-Sell" element={<PropertySell />} />
                  <Route path="/Property-Rent" element={<PropertyRent />} />

                  <Route path="/CustomerCare" element={<CustomerCare />} />
                  <Route path="/FastSellRent" element={<FastSellRent />} />
                  <Route path="/Blog" element={<BlogPosts />} />
                  <Route path="/AboutUs" element={<AboutUs />} />
                  <Route
                    path="/TermsAndConditions"
                    element={<TermsAndConditions />}
                  />
                  <Route path="/ContactUs" element={<ContactUs />} />
                  <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
                  <Route path="/Stay-Safe" element={<StaySafe />} />
                  <Route path="/DownloadApp" element={<DownloadApp />} />

                  <Route
                    path="/Group-Wise-Building"
                    element={<GroupWiseBuilding />}
                  />

                  <Route
                    path="/Interior-Design"
                    element={<InteriorProperty />}
                  />
                  <Route
                    path="/Interior-Details/:id"
                    element={<InteriorPropertyDetails />}
                  />

                  <Route
                    path="/Profile"
                    element={
                      <PrivateRoute>
                        <UserDashboard />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/Edit-Rent-Post/:id"
                    element={
                      <PrivateRoute>
                        <EditRentPost />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/Edit-Sell-Post/:id"
                    element={
                      <PrivateRoute>
                        <EditSellPost />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/profileedit"
                    element={
                      <PrivateRoute>
                        <EditProfile />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/sellpost"
                    element={
                      <PrivateRoute>
                        <UserIsNotSuspendRoute>
                          <SellPost />
                        </UserIsNotSuspendRoute>
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/add-rent-post"
                    element={
                      <PrivateRoute>
                        <UserIsNotSuspendRoute>
                          <RentPost />
                        </UserIsNotSuspendRoute>
                      </PrivateRoute>
                    }
                  />

                  <Route path="/SignIn" element={<SignIn />} />

                  <Route
                    path="/Property-Buy-Sell-Details/:id"
                    element={<PropertySellDetails />}
                  />

                  <Route
                    path="/ProfileDetails/:id"
                    element={<UsersDashboard />}
                  />

                  <Route
                    path="/Property-Rent-Details/:id"
                    element={<PropertyRentDetails />}
                  />

                  <Route
                    path="/Group-Wise-Building-Details/:id"
                    element={<GroupWiseBuildingDetails />}
                  />

                  <Route
                    path="/BlogPostDetails/:id"
                    element={<BlogPostDetails />}
                  />

                  <Route path="/Register" element={<Register />} />
                  <Route path="/ForgotPassword" element={<ForgotPassword />} />
                  <Route
                    path="/ResetPassword/:resetToken"
                    element={<ResetPassword />}
                  />
                  <Route path="*" element={<PageNotFound />} />

                  <Route path="/admin" element={<Admin />} />

                  <Route
                    path="/admin/Group-Wise-Building-Details-Ad/:id"
                    element={
                      <AdRoute>
                        <GroupPostDetailsForAd />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/dashboard"
                    element={
                      <AdRoute>
                        <Dashboard />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/admininfo"
                    element={
                      <AdRoute>
                        <AdminInfo />
                      </AdRoute>
                    }
                  />
                  <Route
                    path="/admin/BlogPost"
                    element={
                      <AdRoute>
                        <BlogPost />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/Notifications"
                    element={
                      <AdRoute>
                        <Notification />
                      </AdRoute>
                    }
                  />
                  <Route
                    path="/admin/interior"
                    element={
                      <AdRoute>
                        <InteriorManagement />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/interiorAddPost"
                    element={
                      <AdRoute>
                        <InteriorAddPost />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/EditInteriotPost/:id"
                    element={
                      <AdRoute>
                        <UpdateInteriorPost />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/usermanage"
                    element={
                      <AdRoute>
                        <UserManagement />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/sellpostmanage"
                    element={
                      <AdRoute>
                        <SellPostManagement />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/rentpostmanage"
                    element={
                      <AdRoute>
                        <RentPostManagement />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/Group-Building-Manage"
                    element={
                      <AdRoute>
                        <GroupBuildingManage />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/Group-Building-Desired-Request"
                    element={
                      <AdRoute>
                        <GroupBuildingDesiredRequest />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/Group-Building-Edit/:id"
                    element={
                      <AdRoute>
                        <UpdateGroupBuildingPost />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="/admin/Set-Group-Building-Post"
                    element={
                      <AdRoute>
                        <SetGroupBuildingPost />
                      </AdRoute>
                    }
                  />

                  <Route
                    path="admin/noticeboard"
                    element={
                      <AdRoute>
                        <NoticeBoard />
                      </AdRoute>
                    }
                  />
                  <Route
                    path="admin/SiteSettings"
                    element={
                      <AdRoute>
                        <SiteSettings />
                      </AdRoute>
                    }
                  />
                </Routes>
              </Suspense>
            </div>
          </NotificationProvider>
        </UserProvider>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
