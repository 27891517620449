import React, { useEffect, useState, useRef } from "react";
import locationData from "../../../../Location.json";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ListLoading from "../../../../components/LocalUi/ListLoading";
import { toast } from "react-hot-toast";
import AdminNavBar from "../../AdminNavBar";
import { Helmet } from "react-helmet";

//icons
import add from "../../../../img/icn/add.png";
//icon
import { CgSpinner } from "react-icons/cg";
import locations from "../../../../img/icn/location.png";
import search from "../../../../img/icn/search.png";
import call from "../../../../img/icn/call.png";
import cancel from "../../../../img/icn/cancel.png";
import action from "../../../../img/icn/action.png";
import premium from "../../../../img/icn/premium.png";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";

const InteriorManagement = () => {
  ///Setting font style
  const { t, i18n } = useTranslation();

  const renderListLoadingPage = () => {
    return <ListLoading />;
  };

  const token = localStorage.getItem("ad-t");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const currentPageQueryParam = queryParams.get("page");
  const initialPage = currentPageQueryParam
    ? parseInt(currentPageQueryParam)
    : 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const postPerPage = 8;
  const [dataFromAPI, setDataFromAPI] = useState([]);
  const [totalPages, setTotalPages] = useState(1); // Track total pages

  // Function to fetch data based on page and limit
  const fetchData = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/InteriorPostCardAd?page=${currentPage}&limit=${postPerPage}`,
        config
      );
      // console.log("Data from server:", response.data);
      setDataFromAPI(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  // Fetch data when currentPage changes
  useEffect(() => {
    fetchData(); // Fetch data when currentPage changes
  }, [currentPage]);

  useEffect(() => {
    setTotalPages(Math.ceil(dataFromAPI.totalItems / postPerPage));
  }, [dataFromAPI]);

  useEffect(() => {
    setTotalPages(Math.ceil(dataFromAPI.totalItems / postPerPage));
  }, [dataFromAPI]);

  // Function to handle opening detail view
  const handleOpenDetail = (item) => {
    navigate(`/Interior-Details/${item.PostID}`);
  };

  // Function to update the URL with the current page
  const updateURL = (page) => {
    navigate(`/admin/Interior?page=${page}`);
  };

  //Search
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");

  const handleDivision = (e) => {
    const getDivisionId = e.target.value;

    if (getDivisionId === "") {
      setDistrict([]);
    } else {
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    setDivisionId(getDivisionId);
  };

  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/InteriorSearchAd?searchText=${searchText}&division=${divisionId}&district=${districtId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSuggestions(response.data.items);
      } catch (error) {
        console.error(error);
      }
    };

    if (searchText.trim() !== "") {
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [searchText]);

  const handleSearch = async () => {
    setSearchLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/InteriorSearchAd?searchText=${searchText}&division=${divisionId}&district=${districtId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.items.length === 0) {
        toast.error("No result found");
      }
      setSearchResults(response.data.items);
      setSuggestions([]);
      setSearchLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  //search clear
  const handleClear = () => {
    setSearchText("");
    setSearchResults([]);
    setSuggestions([]);
  };

  //Action modal
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const handleAction = (event, item) => {
    event.stopPropagation();
    setShowModal(true);
    setSelectedPost(item);
  };

  //Enable Disable post
  const handleToggleEnableDisableClicked = async () => {
    const postId = selectedPost.PostID;
    const newApprovalStatus = !selectedPost.approval;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/ToggleInteriorPostAd`,
        {
          postId: selectedPost.PostID,
          approval: newApprovalStatus,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setSelectedPost({ ...selectedPost, approval: newApprovalStatus });
        setDataFromAPI((prevData) => {
          const updatedEnabledPosts = prevData.enabledPosts.filter(
            (item) => item.PostID !== postId
          );
          const updatedDisabledPosts = prevData.disabledPosts.filter(
            (item) => item.PostID !== postId
          );
          if (newApprovalStatus) {
            updatedEnabledPosts.push({
              ...selectedPost,
              approval: newApprovalStatus,
            });
          } else {
            updatedDisabledPosts.push({
              ...selectedPost,
              approval: newApprovalStatus,
            });
          }
          return {
            ...prevData,
            enabledPosts: updatedEnabledPosts,
            disabledPosts: updatedDisabledPosts,
          };
        });
        toast.success(
          `Post has been ${newApprovalStatus ? "enabled" : "disabled"}.`
        );
      } else {
        toast.error("Failed to toggle the post status.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while toggling the post status.");
    }
  };

  //delete click
  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteClicked = () => {
    setShowDelete(true);
  };

  const handleDeletePost = async () => {
    const postId = selectedPost.PostID;

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/deleteInteriorPostAd/${postId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Post deleted successfully.");
        // Update the state to remove the deleted post
        setDataFromAPI((prevData) => ({
          ...prevData,
          enabledPosts: prevData.enabledPosts
            ? prevData.enabledPosts.filter((item) => item.PostID !== postId)
            : [],
          disabledPosts: prevData.disabledPosts
            ? prevData.disabledPosts.filter((item) => item.PostID !== postId)
            : [],
        }));
      } else {
        toast.error("Failed to delete the post.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while deleting the post.");
    }
    setShowModal(false);
    setShowDelete(false);
  };

  const handleEditClicked = () => {
    const postId = selectedPost.PostID;
    setShowModal(false);
    navigate(`/admin/EditInteriotPost/${postId}`);
  };

  //cancel click
  const handleCancel = () => {
    // setTakeActionSuspend(false);
    setShowDelete(false);
    setShowModal(false);
    //setShowDelete(false);
    // setShowDisable(false);
    // setShowEnable(false);
  };

  return (
    <div className={`MainSection  px-3 min-h-screen`}>
      <Helmet>
        <title>Interior Manage - Admin Panel</title>
      </Helmet>
      <div className="md:flex md:my-5 my-1">
        <AdminNavBar activePage="Interior" />

        <div className="w-full  ">
          <div>
            <p className="font-semibold text-3xl text-gray-500 mb-5">
              Interior Management
            </p>
          </div>

          <div className=" w-full flex justify-between px-5 border border-gray-300 rounded-xl ">
            <div className=" flex  items-center">
              <Link to={"/admin/interiorAddPost"}>
                <div className="md:h-10 h-8 md:w-[150px] w-[130px] flex space-x-1 md:mt-0 mt-2 justify-center items-center bg-green-200 rounded-lg border border-green-500 hover:bg-green-400 duration-200">
                  <img className="md:h-8 h-6 md:w-8 w-6" src={add} alt="add" />
                  <p className=" md:text-base text-sm">Add Post</p>
                </div>
              </Link>
            </div>

            {/* search section*/}
            <div className=" green-red-500 duration-200">
              <div className="flex justify-end  duration-200 ">
                <div className="2xl:h-16  h-28  bg-[#ffffff7c] rounded-lg flex flex-wrap justify-center items-center duration-200 ">
                  {/* Location*/}
                  <div className=" ml-3 duration-200">
                    <div className="flex space-x-2">
                      <div className="relative md:h-10 h-8 ">
                        <select
                          name="Division"
                          required
                          className="md:h-10 h-8 font-semibold text-gray-500  bg-gray-50 border  border-gray-300 rounded-xl focus:ring-gray-500 focus:border-gray-500 block  dark:bg-white dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-gray-500 dark:focus:border-gray-500 duration-200"
                          onChange={(e) => handleDivision(e)}
                        >
                          <option
                            value=""
                            className="text-pink-600 duration-200"
                          >
                            Select Division
                          </option>
                          {locationData.map((getDivision, index) => (
                            <option value={getDivision.division_id} key={index}>
                              {getDivision.division_name}
                            </option>
                          ))}
                        </select>
                      </div>

                      {district.length > 0 && (
                        <div className="relative md:h-10 h-8 duration-200">
                          <select
                            name="states"
                            className="md:h-10 h-8 duration-200 bg-gray-50 border font-semibold text-gray-500 border-gray-300 rounded-xl focus:ring-gray-500 focus:border-gray-500 block  dark:bg-white dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-gray-500 dark:focus:border-gray-500"
                            onChange={(e) => handleDistrict(e)}
                          >
                            <option
                              value=""
                              className="text-pink-600 duration-200"
                            >
                              Select District
                            </option>
                            {district.map((getDistrict, index) => (
                              <option
                                value={getDistrict.district_id}
                                key={index}
                              >
                                {getDistrict.district_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="lg:h-10 md:h-8 flex duration-200">
                    <input
                      type="text"
                      className="lg:h-10 md:h-8 w-full border border-gray-500 lg:ml-2 rounded-xl p-2 duration-200"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Title, Division, District"
                    />
                    <button
                      onClick={handleSearch}
                      className="2xl:h-10 h-8 sm:px-3 sm:bg-green-200 sm:border sm:border-green-500   rounded-xl sm:ml-3 ml-1 space-x-2 flex justify-center items-center duration-200"
                    >
                      {searchLoading && (
                        <CgSpinner
                          size={20}
                          className=" animate-spin duration-200"
                        />
                      )}
                      <span className="hidden sm:inline text-xs 2xl:text-sm duration-200">
                        Search
                      </span>

                      <img
                        src={search}
                        alt="search"
                        className="inline sm:hidden w-6 h-6"
                      />
                    </button>
                  </div>
                  {(searchText.trim() !== "" ||
                    suggestions.length > 0 ||
                    searchResults.length > 0) && (
                    <div
                      onClick={handleClear}
                      className=" w-6 h-6  xl:h-7 xl:w-7 2xl:h-9 2xl:w-9 flex items-center cursor-pointer ml-3 duration-200 "
                    >
                      <img className=" w-full h-full " src={cancel} alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Display loading indicator */}
          {loading ? (
            <div className="h-[70vh] w-full flex justify-center  items-center ">
              <div className=" mt-3 px-2 w-full">{renderListLoadingPage()}</div>
            </div>
          ) : (
            <div className=" flex">
              <div className=" w-full ">
                {suggestions.length > 0 ? (
                  <div className=" duration-200 ">
                    <p className=" text-center text-gray-500 duration-200">
                      You might lokking for...{" "}
                    </p>
                    {suggestions.map((item) => (
                      <DefaultCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleAction={handleAction}
                        showSelections={true}
                      />
                    ))}
                  </div>
                ) : searchResults && searchResults.length > 0 ? (
                  <div className=" ">
                    <p className=" text-center text-gray-500 duration-200 mb-5">
                      {dataFromAPI.totalItems} Search Results
                    </p>
                    {searchResults.map((item) => (
                      <DefaultCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleAction={handleAction}
                        showSelections={true}
                      />
                    ))}
                  </div>
                ) : dataFromAPI.totalItems && dataFromAPI.totalItems > 0 ? (
                  <div className=" items-center  mt-5 duration-200 ">
                    {/* main post */}

                    <div>
                      {dataFromAPI.disabledPosts.length > 0 ? (
                        <p className=" text-red-600 font-semibold">
                          Disabled Posts ({dataFromAPI.disabledPosts.length})
                        </p>
                      ) : null}

                      {dataFromAPI.disabledPosts.length > 0
                        ? dataFromAPI.disabledPosts.map((item) => (
                            <DefaultCard
                              key={item._id}
                              item={item}
                              handleOpenDetail={handleOpenDetail}
                              handleAction={handleAction}
                              showSelections={true}
                              isDisabled={true}
                            />
                          ))
                        : null}

                      <h2 className=" text-green-600 font-semibold">
                        Enabled Posts ({dataFromAPI.enabledPosts.length})
                      </h2>
                      {dataFromAPI.totalItems > 0 ? (
                        dataFromAPI.enabledPosts.map((item) => (
                          <DefaultCard
                            key={item._id}
                            item={item}
                            handleOpenDetail={handleOpenDetail}
                            handleAction={handleAction}
                            showSelections={true}
                            isDisabled={false}
                          />
                        ))
                      ) : (
                        <p>No items found</p>
                      )}
                    </div>

                    {/* Pagination buttons */}
                    <div className="pagination text-center mt-10 h-10">
                      <button
                        className={`pagination-button h-8 w-24 mx-1 mr-3 rounded-md border border-gray-600  duration-200 ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        onClick={() => {
                          if (currentPage > 1) {
                            setCurrentPage(currentPage - 1);
                            updateURL(currentPage - 1);
                          }
                        }}
                      >
                        {"« Previous"}
                      </button>

                      {Array.from({ length: totalPages }).map((_, index) => (
                        <button
                          key={index}
                          className={`pagination-button h-8 w-7 mx-1 rounded-md border border-gray-600  duration-200 ${
                            currentPage === index + 1
                              ? "active bg-gray-300"
                              : ""
                          }`}
                          onClick={() => {
                            setCurrentPage(index + 1);
                            updateURL(index + 1);
                          }}
                        >
                          {index + 1}
                        </button>
                      ))}

                      <button
                        className={`pagination-button h-8 ml-3 w-24 mx-1 rounded-md border border-green-600  duration-200 ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                        onClick={() => {
                          if (currentPage < totalPages) {
                            setCurrentPage(currentPage + 1);
                            updateURL(currentPage + 1);
                          }
                        }}
                      >
                        {"Next »"}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          )}
        </div>
        {showModal && (
          <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white  lg:p-10 p-4 rounded-2xl relative duration-200">
              <button
                className="px-4 py-2 absolute right-0  bg-red-300 rounded-l-md"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <div className=" h-full ">
                <h3 className="text-xl  mb-4 text-red-700 font-bold">
                  Take action
                </h3>
                <div className="flex justify-center items-center">
                  <div className=" ">
                    <div className=" flex justify-center">
                      <img
                        className=" h-[15rem] w-[15rem] "
                        src={selectedPost.coverPhoto}
                        alt="user"
                      />
                    </div>

                    <div className=" text-center">
                      {selectedPost.isPremium && (
                        <div className=" flex  justify-center py-2 ">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div className="flex items-center">
                                  <img
                                    src={premium}
                                    className="h-8 w-8 mr-2"
                                    alt="Premium star"
                                  />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p className=" text-red-600 ">Premium</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      )}

                      <div className=" max-w-[10rem] lg:max-w-[30rem] overflow-hidden">
                        <p
                          className="text-bold line-clamp-1 lg:text-xl cursor-pointer underline text-blue-600"
                          onClick={() => handleOpenDetail(selectedPost)}
                        >
                          {" "}
                          {selectedPost.companyName}
                        </p>
                      </div>

                      <p>Phone: {selectedPost.number} </p>
                      <div className=" max-w-[10rem] lg:max-w-[30rem] overflow-hidden">
                        <p className="mb-3 line-clamp-1">
                          Slogan: {selectedPost.companySlogan}{" "}
                        </p>
                      </div>

                      <p
                        className={`inline uppercase font-bold ${
                          selectedPost.approval === true
                            ? "text-white bg-green-500 p-1 rounded-md"
                            : "text-red-700 bg-red-200 p-1 rounded-md"
                        }`}
                      >
                        {selectedPost.approval === true
                          ? "Post enabled"
                          : "Post disabled"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-10 flex justify-center items-center space-x-3 ">
                  {selectedPost.approval === true ? (
                    <button
                      className="px-4 py-2 bg-red-400 text-white rounded-md"
                      onClick={() =>
                        handleToggleEnableDisableClicked(selectedPost.id)
                      }
                    >
                      Disable Post
                    </button>
                  ) : (
                    <button
                      className="px-4 py-2 bg-green-500 text-white rounded-md"
                      onClick={() =>
                        handleToggleEnableDisableClicked(selectedPost.id)
                      }
                    >
                      Enable Post
                    </button>
                  )}
                  <button
                    className=" px-4 py-2 bg-yellow-500 text-white rounded-md"
                    onClick={handleEditClicked}
                  >
                    {" "}
                    Edit Post
                  </button>
                  <button
                    className=" px-4 py-2 bg-red-500 text-white rounded-md"
                    onClick={handleDeleteClicked}
                  >
                    {" "}
                    Delete Post
                  </button>
                </div>
                <div>
                  {showDelete ? (
                    <div className=" mt-5 lg:py-5 p-2  border bg-red-100 rounded-xl text-center items-center">
                      <div className="  font-bold ">
                        <p className="lg:text-base text-sm">
                          {`Do you really want to delete `}
                          <span className="text-red-500">
                            {selectedPost.companyName}'s
                          </span>
                          {" Store ?"}
                        </p>

                        <p className="lg:text-base text-sm">
                          Its also delete{" "}
                          <span className="text-red-500">
                            {selectedPost.companyName}'s
                          </span>{" "}
                          posts, and all picture.
                        </p>
                      </div>

                      <button
                        className=" px-4 mt-10 py-2 bg-red-500 text-white rounded-md"
                        onClick={handleDeletePost}
                      >
                        Delete Account
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DefaultCard = ({
  item,
  handleOpenDetail,
  handleAction,
  showSelections = false,
  isDisabled,
}) => (
  <div className="flex duration-200">
    <div
      key={item._id}
      className={`border w-full border-gray-400 mb-3 rounded-lg flex justify-between items-center duration-200 cursor-pointer md:p-2 p-1 ${
        isDisabled ? "bg-red-100" : "bg-green-50"
      }`}
      onClick={() => handleOpenDetail(item)}
    >
      <div className="flex duration-200  w-[70%] lg:w-[50%] ">
        <img
          src={item.coverPhoto}
          alt={item.coverPhoto}
          className="mr-2 md:h-24 h-16 rounded-lg md:w-24 w-16"
        />
        <div className="cursor-pointer space-y-[2px] mt-[-5px]">
          <div className="max-w-[15rem] md:max-w-[20rem] lg:max-w-[30rem]  overflow-hidden ">
            <p className="line-clamp-1 font-bold md:text-base text-xs">
              {item.companyName}
            </p>
          </div>
          <div className=" max-w-[15rem] md:max-w-[20rem] lg:max-w-[30rem] overflow-hidden">
            <p className="line-clamp-1 text-gray-500 md:text-base text-xs">
              {item.companySlogan}
            </p>
          </div>
          <div className="flex items-center">
            <img src={locations} alt="locations" className="h-5 w-5 mr-2" />
            <p className="md:text-base text-[10px]">
              {item.subDistrict}, {item.district}, {item.division}
            </p>
          </div>
          <div className="flex items-center">
            <img src={call} alt="prices" className="h-[18px] w-[18px] mr-2" />
            <p className="md:text-base text-[10px]">{item.number}</p>
          </div>
        </div>
      </div>

      <div className=" ">
        {showSelections && (
          <div className="hidden md:block">
            <div className="  grid lg:grid-cols-4 md:grid-cols-3 text-center ">
              {Object.entries(item.selections)
                .filter(([key, value]) => value === true)
                .slice(0, 7)
                .map(([key]) => (
                  <span
                    key={key}
                    className="bg-green-200 text-green-900 px-2 my-1 py-1 rounded-full mr-2 text-xs"
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </span>
                ))}
            </div>
          </div>
        )}
      </div>

      <div className=" flex space-x-4 lg:space-x-10 items-center  ">
        <div className=" flex items-center ">
          {item.isPremium && (
            <div className="">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex items-center">
                      <img
                        src={premium}
                        className="h-8 w-8 mr-2"
                        alt="Premium star"
                      />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className=" text-red-600 ">Premium</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}
        </div>

        <div className="flex duration-200  ">
          <div
            className="cursor-pointer bg-red-200 rounded-md px-2 py-2"
            onClick={(event) => handleAction(event, item)}
          >
            <div className="flex items-center   ">
              <img
                src={action}
                alt="locations"
                className="md:h-7 h-5 md:w-7 w-5 duration-200"
              />
              <p className="md:text-base  hidden ml-2 lg:block duration-200">
                Action
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InteriorManagement;
